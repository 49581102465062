import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";
import { brandTableStore } from "@pages/brands/BrandsPage/brandTableStore";
import { Input, Modal, notification } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import * as React from "react";

export const BrandVerify = observer((): JSX.Element | null => {
    const [brandName, setBrandName] = useState<string>("")

    const onVerify = async () => {
        if (brandName !== brandFormStore.visibleBrandVerifyModal?.brand.name) {
            notification.error({
                message: "Brand name does not match"
            })

            return
        }

        brandFormStore.visibleBrandVerifyModal?.onVerify()
    }

    return <Modal
        width={360}
        title={`Verify clone`}
        okText="Verify & Clone"
        visible={!!brandFormStore.visibleBrandVerifyModal}
        onOk={onVerify}
        onCancel={brandFormStore.hideBrandVerifyModal.bind(null)}
        okButtonProps={{
            loading: brandFormStore.loading,
            type: "danger",
        }}
        cancelButtonProps={{
            disabled: brandFormStore.loading,
        }}
        maskClosable={false}
        bodyStyle={{ maxHeight: 450, display: "flex", flexDirection: "column", gap: 12, alignItems: "center" }}>
            <Input width={320} placeholder="Enter name of TO Brand" value={brandName} onChange={e => setBrandName(e.currentTarget.value)} onPaste={e => e.preventDefault()} />
    </Modal>;
});
