import { DataTable } from "@app/components/DataTable/DataTable";
import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";
import { ClearBrand } from "@pages/brands/BrandForm/ClearBrand";
import { BrandVerify } from "@pages/brands/BrandForm/BrandVerify";
import { ImportMenuModal } from "@pages/brands/BrandForm/ImportMenu";
import { ImportMenuXLS } from "@pages/brands/BrandForm/ImportMenuXLS";
import { CloneBrand } from "@pages/brands/BrandForm/CloneBrand";
import { ClientForm } from "@app/pages/clients/ClientForm/ClientForm";
import { sessionStore } from "@stores/SessionStore";
import { Button, Dropdown, Icon, Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { observer } from "mobx-react";
import * as React from "react";
import { brandTableStore } from "./brandTableStore";

@observer
export class BrandsPage extends React.Component {
    public componentDidMount(): void {
        brandTableStore.dataProvider.loadData();
    }

    private handleMenuClick = (e: ClickParam) => {
        if (e.key === "clone") {
            brandFormStore.showCloneBrandModal();
        } else if (e.key === "clear") {
            brandFormStore.showClearBrandModal()
        }
    };

    public render(): JSX.Element {
        return (
            <div>
                <h2>Brands ({brandTableStore.dataProvider.pagination.total})</h2>
                <div>
                    <ClientForm />
                    {brandFormStore.visibleClearBrandModal && <ClearBrand />}
                    {brandFormStore.visibleCloneBrandModal && <CloneBrand />}
                    {brandFormStore.visibleBrandVerifyModal && <BrandVerify />}
                    <DataTable store={brandTableStore} bordered
                               extraHeaderElements={sessionStore.role?.level === 5 && <Dropdown overlay={<Menu onClick={this.handleMenuClick}>
                                   <Menu.Item key="clone">
                                       Clone
                                   </Menu.Item>
                                   <Menu.Item key="clear">
                                       Clear
                                   </Menu.Item>
                               </Menu>}>
                                   <Button type="danger">
                                       Action <Icon type="down" />
                                   </Button>
                               </Dropdown>} />
                    <ImportMenuModal />
                    <ImportMenuXLS />
                </div>
            </div>
        );
    }
}
