import * as React from "react";
import { Button, Dropdown, Select, Table } from "antd";
import { TableProps } from "antd/lib/table/interface";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { ColumnsManager } from "./ColumnsManager";
import { ErrorBoundary } from "../misc/ErrorBoundary";
import { get } from "lodash";
import { DataTableFilter } from "@app/components/DataTable/DataTableFilter";
import _ from "lodash";
import { IS_MOBILE } from "@app/config/main";

interface IDataTableProps<T extends IData> extends TableProps<T> {
    store: DataTableStore<T>;
    extraHeaderElements?: React.ReactNode;
}

@observer
export class DataTable<T extends IData> extends React.Component<IDataTableProps<T>> {
    constructor(props: IDataTableProps<T>) {
        super(props);
    }

    public tableProps(): TableProps<T> {
        const { store, ...rest } = this.props;
        const columns = IS_MOBILE ? toJS(store.columns) : toJS(store.columns);
        return {
            rowKey: "id",
            useFixedHeader: false,
            scroll:
                IS_MOBILE || 1
                    ? {
                          x: "min-content",
                      }
                    : undefined,
            tableLayout: "fixed",
            loading: store.dataProvider.loading,
            dataSource: toJS(store.dataProvider.list),
            columns: columns,
            pagination: toJS(store.dataProvider.pagination),
            ...rest,
        };
    }

    public renderFilters(): React.ReactNode {
        return (
            this.props.store.config.filters &&
            this.props.store.config.filters.map(filter => (
                <DataTableFilter key={filter.name} store={this.props.store} filter={filter} />
            ))
        );
    }

    public createButton(): JSX.Element | null {
        const DataForm = this.props.store.config.form;
        const formStore = this.props.store.config.formStore && this.props.store.config.formStore();
        const { store } = this.props;
        return (
            <>
                {store.config.reloadBtn && <Button icon="reload" onClick={store.dataProvider.loadData} />}
                {formStore ? (
                    <Button onClick={formStore.onCreateClick} type="primary" icon="plus">
                        Create
                    </Button>
                ) : null}
                {DataForm && <DataForm />}
            </>
        );
    }

    public columnsManager(): JSX.Element | null {
        if (this.props.store.config.columnsManager) {
            const columns = get(this.props.store.config.columnsManager, "hiddenColumns", []);
            return <ColumnsManager store={this.props.store} defaultHiddenColumns={columns} />;
        } else {
            return null;
        }
    }

    private onSortChange = (val: any) => {
        this.props.store.dataProvider.params.sort = [val, "-1"];
        this.props.store.dataProvider.loadData();
    };

    private renderSortOptions(): React.ReactNode {
        const options = this.props.store.config.sortOptions;
        if (!options) {
            return;
        }

        return (
            <Select
                placeholder={options.placeholder}
                allowClear
                style={{ width: 200 }}
                onChange={this.onSortChange}
                optionFilterProp="children"
                filterOption={false}
            >
                {_.map(options.values, (data, key) => (
                    <Select.Option key={key} value={key}>
                        {data}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    public render(): JSX.Element {
        return (
            <ErrorBoundary>
                <div className="data-table-filters-container">
                    {this.createButton()}
                    {this.props.extraHeaderElements}
                    {this.columnsManager()}
                    {this.renderSortOptions()}
                    {this.renderFilters()}
                </div>
                <Table {...this.tableProps()} />
            </ErrorBoundary>
        );
    }
}
