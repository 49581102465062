import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";
import { brandTableStore } from "@pages/brands/BrandsPage/brandTableStore";
import { Modal, notification, Select, Spin } from "antd";
import { observer } from "mobx-react";
import * as React from "react";

export const CloneBrand = observer((): JSX.Element | null => {
    const onOk = () => {
        if (!brandFormStore.cloneBrandType) {
            notification.error({
                message: "Please select what to clone"
            })

            return;
        }

        if (!brandFormStore.cloneBrandFrom || !brandFormStore.cloneBrandTo) {
            notification.error({
                message: "Please select from & to brands"
            })

            return;
        } else if (brandFormStore.cloneBrandFrom.id === brandFormStore.cloneBrandTo.id) {
            notification.error({
                message: "Please select different brands"
            })

            return
        }

        const type = brandFormStore.cloneBrandType!
        const from = brandFormStore.cloneBrandFrom!
        const to = brandFormStore.cloneBrandTo!

        const onClone = async () => {
            let res;

            switch (type) {
                case "brand":
                    res = await brandTableStore.dataProvider.clearAction(from, "clone", {
                        destination: to.name
                    })
                    break
                case "emenu":
                    res = await brandTableStore.dataProvider.clearAction(to, "fillMenu", {
                        from: from.id
                    })
                    break
            }

            if (res?.success) {
                brandFormStore.hideCloneBrandModal()
                brandFormStore.hideBrandVerifyModal()

                notification.success({
                    message: "Brand cloned successfully"
                })
            }
        }

        brandFormStore.showBrandVerifyModal({
            brand: to,
            onVerify: onClone,
        });
    }

    return <Modal
        width={400}
        title={`Clone brand`}
        okText="Clone"
        visible={brandFormStore.visibleCloneBrandModal}
        onOk={onOk}
        onCancel={brandFormStore.hideCloneBrandModal.bind(null)}
        okButtonProps={{
            loading: brandFormStore.loading,
            type: "danger"
        }}
        cancelButtonProps={{
            disabled: brandFormStore.loading,
        }}
        maskClosable={false}
        bodyStyle={{ maxHeight: 450, display: "flex", flexDirection: "column", gap: 12, alignItems: "center" }}>
        <div>
            <Select
                style={{ width: 320 }}
                placeholder={"What to clone?"}
                onChange={(val: "brand" | "emenu") => brandFormStore.cloneBrandType = val}
                >
                    <Select.Option key={"brand"} value={"brand"}>
                        Brand
                    </Select.Option>
                    <Select.Option key={"emenu"} value={"emenu"}>
                        Menu
                    </Select.Option>
            </Select>
        </div>

        <div>
            <Select
                style={{ width: 320 }}
                placeholder="From brand"
                showSearch
                optionFilterProp="children"
                notFoundContent={brandFormStore.fetching ? <Spin size="small" /> : null}
                onSearch={brandFormStore.onBrandSearch}
                filterOption={false}
                onChange={(brand: number) => brandFormStore.cloneBrandFrom = brandFormStore.brands.find(c => c.id === brand)!}
            >
                {brandFormStore.brands.map(c => (
                    <Select.Option key={c.id} value={c.id}>
                        {c.title} - {c.name}
                    </Select.Option>
                ))}
            </Select>
        </div>

        <div>
            <Select
                style={{ width: 320 }}
                placeholder="To brand"
                showSearch
                optionFilterProp="children"
                notFoundContent={brandFormStore.fetching ? <Spin size="small" /> : null}
                onSearch={brandFormStore.onBrandSearch}
                filterOption={false}
                onChange={(brand: number) => brandFormStore.cloneBrandTo = brandFormStore.brands.find(c => c.id === brand)!}
            >
                {brandFormStore.brands.map(c => (
                    <Select.Option key={c.id} value={c.id}>
                        {c.title} - {c.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    </Modal>;
});
