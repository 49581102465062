import { BrandStatus } from "@app/config/enums";
import { Spin, Tag, Tooltip } from "antd";
import * as React from "react";

const colors: any = {
    [BrandStatus.ACTIVE]: "green",
    [BrandStatus.PENDING]: "orange",
    [BrandStatus.DELETE]: "red",
    [BrandStatus.DELETING]: "red",
    [BrandStatus.CLOSE]: "magenta",
    [BrandStatus.EXPIRED]: "volcano",
    [BrandStatus.FREEZE]: "blue",
    [BrandStatus.OUT_OF_SERVICE]: "red",
    [BrandStatus.IN_MAINTENANCE]: "purple",
};

export function BrandStatusTag({status}: { status: BrandStatus }): JSX.Element {
    return status !== BrandStatus.IN_MAINTENANCE ? <Tag color={colors[status]} style={{fontSize: 12}}>
        {BrandStatus[status]}
    </Tag> : <Tooltip title={"In maintenance mode"}><Spin size="small" /></Tooltip>;
}
