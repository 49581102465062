import { dummyBrand, getBrandById } from "@app/actions/brand-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { ajax } from "@app/lib/ajax";
import { clientFormStore } from "@app/pages/clients/ClientForm/ClientFormStore";
import { action, computed, observable, toJS } from "mobx";
import { brandTableStore } from "../BrandsPage/brandTableStore";
import { attempt } from "@lib/utils";
import { actionByName, getData, saveData } from "@lib/api-utils";
import * as React from "react";
import { sendAction } from "@app/actions/terminal-actions";
import JSONFormData from "json-form-data";
import { message } from "antd";
import _ from "lodash";

class BrandFormStore extends FormDrawerStore<IBrand> {
    @observable public _data: IBrand;
    public autofocus = "name";
    public dataName = "brand";
    public dummyDataFn = dummyBrand;
    public apiUrl = endpoints.brand;
    @observable public fetching: boolean = false;
    @observable public showDeleteOption: boolean = false;

    @observable public clients: IClient[] = [];
    @observable public db_configs: IDbConfig[] = [];
    @observable public tags: ITag[] = [];
    @observable public partners: IPartner[] = [];
    @observable public brands: IBrand[] = [];
    @observable public packages: IPackage[] = [];
    @observable public venues: IVenue[] = [];
    @observable public modulePackages: IPackage[] = [];
    @observable public selectedProductTypes: string[] = [];
    @observable public actionName: string = "";
    @observable public actionStageName: string = "";
    @observable public actionVenueId: number; // TODO why is this venue id? it should be brand. confused.
    @observable public actionSelectVenueId: number | undefined;
    @observable public actionSelectUntilDate: any;
    @observable public brandExtra: IBrandExtra;
    @observable public visibleClearBrandModal = false;
    @observable public visibleCloneBrandModal = false;
    @observable public visibleBrandVerifyModal?: {
        brand: IBrand;
        onVerify: () => void;
    }
    @observable public clearBrandType?: "clearFinance" | "clearInventory" | "clearSale"
    @observable public cloneBrandType?: "brand" | "emenu"
    @observable public cloneBrandFrom?: IBrand;
    @observable public cloneBrandTo?: IBrand;
    @observable public clearBrand?: IBrand;

    public formDrawerProps = {
        width: 650,
    };

    @observable public productTypes: string[] = [
        "GOODS",
        "INGREDIENT",
        "DISH",
        "PREPARATION",
        "MODIFICATORS",
        "CATEGORIES",
        "TIMER",
    ];

    public beforeShow = async (): Promise<void> => {
        this.showDeleteOption = false;
        this.actionName = "";
        this.actionSelectVenueId = undefined;

        await Promise.all([
            this.loadExtraData(),
            this.loadClients(),
            this.loadPackages(),
            this.loadModulePackages(),
            this.loadBrands(),
            this.loadPartners(),
            this.loadVenues(),
            this.loadDbConfigs(),
            this.loadTags(),
        ]);
        // subscriptionFormStore.brand = this.data;
    };

    @computed
    get title(): string {
        return (this.data.id ? "Edit " : "Create ") + this.dataName + ": " + (this._data?.name ?? "");
    }

    // @ts-ignore
    get tableStore(): DataTableStore {
        return brandTableStore;
    }

    set tableStore(s: DataTableStore) {}

    public async loadClients(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.client,
            params: {
                sort: ["id", "=", -1],
            },
        });
        this.clients = resp.data;
    }

    public async loadDbConfigs(): Promise<void> {
        const resp = await ajax.get({
            url: "db-configs",
        });

        this.db_configs = resp.data;
    }

    public async loadTags(): Promise<void> {
        const resp = await ajax.get({
            url: "tags?type=brand",
        });

        this.tags = resp.data;
    }

    public async loadBrands(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.brand,
            params: {
                sort: ["id", "=", -1],
            },
        });
        this.brands = resp.data.filter((brand: IBrand) => brand.id !== this._data.id);
    }

    private loadExtraData = async () => {
        const resp = await ajax.get<IBrandExtra[]>({
            url: endpoints.brandExtra,
            params: {
                filters: [["brand_id", this._data.id!]],
            },
        });

        this.brandExtra = resp.data.reduce(
            (t, c) => {
                t.amount += c.amount;
                t.last_month_amount += c.last_month_amount;
                t.receipt_count += c.receipt_count;
                t.last_month_receipt_count += c.last_month_receipt_count;
                t.page_view_count += c.page_view_count;
                t.last_check_date = t.last_check_date > c.last_check_date ? t.last_check_date : c.last_check_date;
                return t;
            },
            {
                amount: 0,
                last_month_amount: 0,
                receipt_count: 0,
                last_month_receipt_count: 0,
                last_check_date: "",
                page_view_count: 0,
                currency: resp.data[0]?.currency,
                brand_id: resp.data[0]?.brand_id,
            } as IBrandExtra,
        );
    };

    public async loadPartners(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.partners,
        });
        this.partners = resp.data;
    }

    public async loadPackages(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                with: ["prices"],
                filters: [["type", "PACKAGE"]],
            },
        });
        this.packages = resp.data;
    }

    public async loadVenues(): Promise<IVenue[]> {
        if (this._data.id) {
            const resp = await ajax.get({
                url: `brand/${this._data.id}/venues`,
            });
            this.venues = resp?.data || [];
            return resp?.data || [];
        }
        return new Promise(() => null);
    }

    public async createVenue(brandId: number | undefined = this._data.id, name: string): Promise<IApiResponse<any>> {
        return await ajax.post({
            url: `brand/${brandId}/venues`,
            data: {
                name,
            },
        });
    }

    public async deleteVenue(brandId: number | undefined = this._data.id, venueId: number): Promise<IApiResponse<any>> {
        return await ajax.delete({
            url: `brand/${brandId}/venues/${venueId}`,
        });
    }

    public async updateVenue(
        brandId: number | undefined = this._data.id,
        venueId: number,
        name: string,
        status: number,
    ): Promise<IApiResponse<any>> {
        return await ajax.post({
            url: `brand/${brandId}/venues/${venueId}?_method=PUT`,
            data: {
                name,
                status,
            },
        });
    }

    public async loadModulePackages(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                with: ["prices"],
                filters: [["type", "MODULE"]],
            },
        });
        this.modulePackages = resp.data;
    }

    @action
    public onCreateClientClick = () => {
        clientFormStore.onCreateClick();
        clientFormStore.afterSaveCallback = action((client: IClient) => {
            this.clients.push(client);
            this._data.client = client;
            this._data.client_id = client.id!;
            // this._data.country = client.country;
            this.formUtils!.setFieldsValue({ client_id: client.id });
        });
    };

    @action
    public setClientId = (id: number) => {
        this.data.client_id = id;
        this.data.client = _.find(this.clients, { id });
    };

    @action
    public setSellerId = (id: number) => {
        this.data.seller_id = id;
    };

    @action
    public setPartnerId = (id: number) => {
        this.data.partner_id = id;
        this.data.partner = _.find(this.partners, { id });
    };

    @action
    public setTags = (tags: any) => {
        this.data.tags = tags;
    };

    @action
    public setDeletingProductType = (type: string[]) => {
        this.selectedProductTypes = type;
    };

    public onDeleteProduct = async (): Promise<void> => {
        this.loading = true;
        await attempt(async () => {
            const params = {
                type: toJS(this.selectedProductTypes),
            };
            await actionByName(this.apiUrl, this.data.id!, "ClearMenu", params);
            this.selectedProductTypes = [];
        });
        this.loading = false;
    };

    public onFetchClient = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1],
        };
        if (value) {
            params = {
                filters: [["email", "=", value]],
            };
        }
        const resp = await ajax.get({
            url: endpoints.client,
            params,
        });
        this.fetching = false;
        this.clients = resp.data;
    }, 500);

    public onMenuSave = async (): Promise<void> => {
        attempt(
            async () => {
                this.loading = true;
                const url = `${this.apiUrl}/${this.data.id}/action/ProductImport`;
                const resp = await saveData(
                    { url },
                    {
                        content: this.data.importedMenu,
                    },
                );
                if (resp.success) {
                    await saveData(
                        { url },
                        {
                            content: this.data.importedMenu,
                            passed: true,
                        },
                    );
                    this.loading = false;
                    this.hideModal();
                }
            },
            err => {
                if (err.field && this.formUtils) {
                    _.forEach(err.field, (value, name) => {
                        this.formUtils!.setFields({ [name]: { errors: value.map(Error) } });
                    });
                    this.fieldsWithError = err.field;
                }
            },
            () => {
                this.loading = false;
            },
        );
    };

    public onMenuXLSSave = async (): Promise<void> => {
        attempt(
            async () => {
                this.loading = true;
                const url = `${this.apiUrl}/${this.data.id}/action/ExcelProductImport`;
                const formData = JSONFormData({
                    file: this.data.importedExcelMenu,
                });

                await ajax
                    .post({
                        url,
                        data: formData,
                    })
                    .then(() => {
                        message.success("Success!");
                        this.hideModalSecondary();
                        this.loading = false;
                    })
                    .catch(ex => {
                        this.loading = false;
                    });
            },
            err => {
                this.loading = false;
            },
            () => {
                this.loading = false;
            },
        );
    };

    public onExcelMenuChange = (file: File) => {
        this.data.importedExcelMenu = file;
    };

    public onMenuChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.data.importedMenu = e.currentTarget.value;
    };

    public showDelete = () => {
        this.showDeleteOption = !this.showDeleteOption;
    };

    @action
    public setPackageId = (id: number) => {
        const newPackage = _.find(this.packages, { id });
        if (newPackage) {
            const modules = toJS(this.data.subscription!.packages).filter(p => p.type === "MODULE");
            this.data.subscription!.packages = [newPackage, ...modules];
        }
    };

    @action
    public setModulePackageId = (ids: number[]) => {
        this.data.subscription!.modules = ids;
    };

    public afterSave = (d: IBrand) => {
        if (this.tableStore) {
            // keeping old data before updating it
            const oldData = _.cloneDeep(this.tableStore.dataProvider.list.find(dp => dp.id === d.id));

            // updating stale data on table
            this.tableStore.dataProvider.updateLocalCopyOfData(d, true);

            if (oldData && oldData.stage !== d.stage) {
                this.updateTerminalsStage(d);
            }
            // await sendAction(this.props.terminal, key as any);
        }
    };

    public updateTerminalsStage = async (d: IBrand): Promise<void> => {
        attempt(
            async () => {
                const url = `${this.apiUrl}/${d.id}/terminal`;
                const resp = await getData({ url });
                if (resp.success) {
                    const terminals = resp.data as ITerminal[];
                    const brand = await getBrandById(d.id as any);
                    terminals
                        .filter(t => t.device_id)
                        .forEach(terminal => {
                            terminal.brand = brand.name;
                            sendAction(terminal, "switch-stage");
                        });
                }
            },
            err => {
                console.log(err);
            },
        );
    };

    public onBrandSearch = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1],
        };
        if (value) {
            params = {
                filters: [["name", value]],
            };
        }
        const resp = await ajax.get({
            url: endpoints.brand,
            params,
        });
        this.fetching = false;
        this.brands = resp.data
    }, 500);

    public prepareActionPayload = () => {
        switch (this.actionName) {
            case "AnbarTest":
                return { stage: this.actionStageName };
            case "fillMenu":
                return { from: this.actionVenueId };
            default:
                return {
                    venue_id: this.actionSelectVenueId ?? undefined,
                    until_date: this.actionSelectUntilDate ?? undefined,
                };
        }
    };

    public onActionRun = async (d: IData) => {
        const actionParams = this.prepareActionPayload();
        return await this.tableStore.dataProvider.clearAction(d, this.actionName, actionParams);
    };

    @action
    public showCloneBrandModal = () => {
        this.setData(this._data);
        this.visibleCloneBrandModal = true;
    }

    @action
    public showClearBrandModal = () => {
        this.setData(this._data);
        this.visibleClearBrandModal = true;
    }

    @action
    public hideClearBrandModal = () => {
        this.setData(undefined as any);
        this.visibleClearBrandModal = false;
        brandFormStore.clearBrandType = undefined
        brandFormStore.clearBrand = undefined
        brandFormStore.brands = []
    }

    @action
    public hideCloneBrandModal = () => {
        this.setData(undefined as any);
        this.visibleCloneBrandModal = false;
        brandFormStore.cloneBrandType = undefined
        brandFormStore.cloneBrandFrom = undefined
        brandFormStore.cloneBrandTo = undefined
        brandFormStore.brands = []
    }

    @action
    public showBrandVerifyModal = ({brand, onVerify}: {brand: IBrand, onVerify: () => void}) => {
        this.setData(this._data);
        this.visibleBrandVerifyModal = {
            brand,
            onVerify
        };
    }

    @action
    public hideBrandVerifyModal = () => {
        this.setData(undefined as any);
        this.visibleBrandVerifyModal = undefined;
    }

    public onActionChange = (value: string) => {
        this.actionName = value;
    };

    public onActionVenueSelect = (value: number) => {
        this.actionSelectVenueId = value;
    };
}

export const brandFormStore = new BrandFormStore();
