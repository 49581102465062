import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";
import { brandTableStore } from "@pages/brands/BrandsPage/brandTableStore";
import { Button, Modal, notification, Popconfirm, Select, Spin } from "antd";
import { observer } from "mobx-react";
import * as React from "react";

export const ClearBrand = observer((): JSX.Element | null => {
    const onOk = () => {
        if (!brandFormStore.clearBrandType) {
            notification.error({
                message: "Please select what to clear"
            })

            return;
        }

        if (!brandFormStore.clearBrand) {
            notification.error({
                message: "Please select brand"
            })

            return;
        }

        const type = brandFormStore.clearBrandType!
        const brand = brandFormStore.clearBrand!

        brandFormStore.showBrandVerifyModal({
            brand: brandFormStore.clearBrand,
            onVerify: async () => {
                const res = await brandTableStore.dataProvider.clearAction(brand, type, )

                if (res?.success) {
                    brandFormStore.hideClearBrandModal()
                    brandFormStore.hideBrandVerifyModal()

                    notification.success({
                        message: "Brand cleared successfully"
                    })
                }
            }
        });
    }

    return <Modal
        width={400}
        title={`Clear brand`}
        okText="Clear"
        visible={brandFormStore.visibleClearBrandModal}
        onOk={onOk}
        onCancel={brandFormStore.hideClearBrandModal.bind(null)}
        okButtonProps={{
            loading: brandFormStore.loading,
            type: "danger"
        }}
        cancelButtonProps={{
            disabled: brandFormStore.loading,
        }}
        maskClosable={false}
        bodyStyle={{ maxHeight: 450, display: "flex", flexDirection: "column", gap: 12, alignItems: "center" }}>
        <div>
            <Select
                style={{ width: 320 }}
                placeholder={"What to clear?"}
                onChange={(val: "clearFinance" | "clearInventory" | "clearSale") => brandFormStore.clearBrandType = val}
                >
                    <Select.Option key={"clearFinance"} value={"clearFinance"}>
                        Finance
                    </Select.Option>
                    <Select.Option key={"clearInventory"} value={"clearInventory"}>
                        Inventory
                    </Select.Option>
                    <Select.Option key={"clearSale"} value={"clearSale"}>
                        Sale
                    </Select.Option>
            </Select>
        </div>

        <div>
            <Select
                style={{ width: 320 }}
                placeholder="Brand"
                showSearch
                optionFilterProp="children"
                notFoundContent={brandFormStore.fetching ? <Spin size="small" /> : null}
                onSearch={brandFormStore.onBrandSearch}
                filterOption={false}
                onChange={(brand: number) => brandFormStore.clearBrand = brandFormStore.brands.find(c => c.id === brand)!}
            >
                {brandFormStore.brands.map(c => (
                    <Select.Option key={c.id} value={c.id}>
                        {c.title} - {c.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    </Modal>;
});
