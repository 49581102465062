export const API_URL = getAPI_URL();
// export const WS_API_URL = "https://ws.clopos.com/daytotals2";
export const PUBLIC_API_URL = "https://public-api.clopos.com";

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const IS_MOBILE = window.innerWidth < 800;
function getAPI_URL(): string {
    return `https://papi.clopos.com/`;
}

// https://storage.googleapis.com/clopos-log/demo/receipt.json
// https://storage.googleapis.com/clopos-log/demo/customer.json
// https://storage.googleapis.com/clopos-log/demo/request.json

export const Actions = {
    // clearFinance: "Clear Finance",
    // clearInventory: "Clear Inventory",
    // clone: "Clone",
    // clearSale: "Clear Sale",
    ImportEmenu: "Import Emenu",
    // fillMenu: "Clone Menu",
};
